import React from 'react';
import Image from '@/components/ReusableUI/Image';
import Link from 'next/link';
import { useAnalytics } from "@/analytics/AnalyticsProvider";
import { LogoClicked } from "@/analytics/events";

const LogoImage = ({ id = 'logo', clickable = true, image = null, url = null }) => {
    const analytics = useAnalytics();

    const handleClick = (e) => {
        // e.preventDefault();
        analytics.track(LogoClicked);
    };

    return (
        <Link id={id} href={clickable ? '/' : '#'} passHref>
            <a
                id={id}
                className='flex items-center h-8 sm:h-[50px] w-auto max-w-[150px] cursor-pointer '
                onClick={handleClick}
            >
                {image ? (
                    <Image
                        image={image}
                        objectFit='contain'
                        objectPosition='left'
                        className='h-full w-auto mr-auto text-left cursor-pointer'
                    />
                ) : (
                    <img
                        id={id}
                        src={url}
                        alt='Muzology Logo'
                        height='100%'
                        width='auto'
                        // className='h-[66px] w-auto  '
                    />
                )}
            </a>
        </Link>
    );
};

export default LogoImage;
